import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from '../components/breadcrumb/breadcrumb.component';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

  constructor(private dataSrv: DataService, @Inject(LOCALE_ID) public locale: string) { }

  clearBreadcrumbs(){
    this.breadcrumbs.next([]);
  }

  setGrade(grade: any){
    let lbl = this.dataSrv.getGradeLable(grade);
    if(grade <12){
      this.setCrumb(lbl, `/grade/${grade}`, true);
    }else{
      this.setCrumb(lbl, `/grade/${grade}/stream`, true);
    }
    
  }

  setStream(stream: any){
    let lbl = this.dataSrv.getStreamLable(stream);
    this.setCrumb(lbl, `/grade/13/stream/${stream}`, false);
  }

  setCrumb(lable: string, route: string, clear: boolean = false){
    let str_array:any ={"si":"මුල් පිටුව","en":"Home","ta":"முகப்பு"} 
    let tmp  = str_array[this.locale]

    let crumbs = clear ? [{lable: tmp, route: '/'}] : this.breadcrumbs.value;
    crumbs.push({
      lable,
      route
    });
    this.breadcrumbs.next(crumbs);
  }
}
