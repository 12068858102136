import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOCALE_ID, Inject } from '@angular/core';
import { WindowService } from '../../services/window.service';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutConfirmComponent } from 'src/app/login/pages/components/logout-confirm/logout-confirm.component';
import { AlGreetModalContentComponent } from '../../components/al-greet-modal-content/al-greet-modal-content.component';

// declare var window: any;

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  public grades: any = [];
  isLoggedIn: boolean;

  constructor(public router: Router, private windowSrv: WindowService, private data: DataService,
    @Inject(LOCALE_ID) public locale: string, private authService: AuthService, public modalService: NgbModal) {
    this.isLoggedIn = !!localStorage.getItem('__dp-user');
    // console.log(this.isLoggedIn);

  }

  public screenWidth: any;

  ngOnInit(): void {
    if (this.windowSrv.isBrowser) this.screenWidth = this.windowSrv.nativeWindow.innerWidth;
    this.grades = this.data.grades;

    // store timestamps for each language
    // const lastVisitTimestamp = localStorage.getItem(`lastVisitTimestamp_${this.locale}`);

    // if (!lastVisitTimestamp || this.shouldClearFlag(+lastVisitTimestamp)) {
    //   // It's the first visit or more than 2 hours have passed since the last visit
    //   // this.openModalAlGreet();

    //   // Set the timestamp for the current visit
    //   localStorage.setItem(`lastVisitTimestamp_${this.locale}`, Date.now().toString());
    // }
  }

  shouldClearFlag(lastVisitTimestamp: number): boolean {
    // Check if 2 hours (7200000 milliseconds) have passed since the last visit
    const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
    return Date.now() - lastVisitTimestamp > twoHoursInMilliseconds;
  }

  changeLang(lang: string) {
    // console.log(lang)

    if (lang == 'si') {
      this.router.navigate(['']);
    } else {
      this.router.navigate([lang]);
    }

  }

  getCurrentLang() {
    return this.locale;
  }

  gotohome() {
    this.router.navigate(['/']);
  }

  logout() {
    const modelRef = this.modalService.open(LogoutConfirmComponent, { centered: true, backdrop: false });
    modelRef.componentInstance.modalRef = modelRef;
  }

  openModalAlGreet() {
    const modalRef = this.modalService.open(AlGreetModalContentComponent, { centered: true, backdrop: 'static' });
  }
}