import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  grades: any[] = [
    {
        "id": "1",
        "si": "1 ශ්‍රේණිය",
        "en": "Grade 1",
        "ta": "தரம் 1"
    },
    {
        "id": "2",
        "si": "2 ශ්‍රේණිය",
        "en": "Grade 2",
        "ta": "தரம் 2"
    },
    {
        "id": "3",
        "si": "3 ශ්‍රේණිය",
        "en": "Grade 3",
        "ta": "தரம் 3"
    },
    {
        "id": "4",
        "si": "4 ශ්‍රේණිය",
        "en": "Grade 4",
        "ta": "தரம் 4"
    },
    {
        "id": "5",
        "si": "5 ශ්‍රේණිය",
        "en": "Grade 5 ",
        "ta": "தரம் 5"
    },
    {
        "id": "6",
        "si": "6 ශ්‍රේණිය",
        "en": "Grade 6",
        "ta": "தரம் 6"
    },
    {
        "id": "7",
        "si": "7 ශ්‍රේණිය",
        "en": "Grade 7",
        "ta": "தரம் 7"
    },
    {
        "id": "8",
        "si": "8 ශ්‍රේණිය",
        "en": "Grade 8",
        "ta": "தரம் 8"
    },
    {
        "id": "9",
        "si": "9 ශ්‍රේණිය",
        "en": "Grade 9",
        "ta": "தரம் 9"
    },
    {
        "id": "10",
        "si": "10 ශ්‍රේණිය",
        "en": "Grade 10",
        "ta": "தரம் 10"
    },
    {
        "id": "11",
        "si": "11 ශ්‍රේණිය",
        "en": "Grade 11",
        "ta": "தரம் 11"
    },
    {
        "id": "13",
        "si": "12/13 ශ්‍රේණි",
        "en": "Grade 12/13 ",
        "ta": "தரம் 12/13"
    }
  ];

  streams: any[] =[
    {
      "id":"1",
      "key":"physical-science",
      "si": "භෞතික විද්‍යාව",
      "en": "Physical Science",
      "ta": "பெளதீக விஞ்ஞானம்",
      "icon": "./assets/icons/subjects/maths.png"
    },
    {
      "id":"2",
      "key":"biological-science",
      "si": "ජීව විද්‍යාව ",
      "en": "Biological Science",
      "ta": "உயிரியல் விஞ்ஞானம்",
      "icon": "./assets/icons/subjects/science.png"
    },
    {
      "id":"3",
      "key":"commerce",
      "si": "වාණිජ විෂයයන්",
      "en": "Commerce",
      "ta": "வணிகக்கல்வி",
      "icon": "./assets/icons/subjects/businessStudies.png"
    },
    {
      "id":"4",
      "key":"technology",
      "si": "තාක්ෂණ විෂයයන්",
      "en": "Technology",
      "ta": "தொழில்நுட்பம்",
      "icon": "./assets/icons/subjects/it.png"
    },
    {
      "id":"5",
      "key":"other",
      "si": "වෙනත් විෂයයන්",
      "en": "Other subjects",
      "ta": "ஏனைய பாடங்கள்",
      "icon": "./assets/icons/subjects/arts.png"
    }
  ]

  brands: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  whatWeOffer: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  stories: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient, @Inject(LOCALE_ID) public locale: string) {
    this.http.get<any[]>(`${this.baseUrl}/assets/data/brands.json`).subscribe((response => {
      this.brands.next(response);
      this.loaded.next(true);
    }))

    this.http.get<any[]>(`${this.baseUrl}/assets/data/what-we-offer.json`).subscribe((response => {
      this.whatWeOffer.next(response);
      this.loaded.next(true);
    }))
  }

  getStories(): Observable<any> {
    return this.http.get<any[]>(`${this.baseUrl}/assets/data/stories.json`);
  }

  getGradeLable(grade: any): string{
    let grd = this.grades.find((elem) => elem.id == grade);
    if(grd) return grd[this.locale];

    return '';
  }

  getStreamLable(stream: any): string{
    let str = this.streams.find((elem) => elem.key == stream);
    if(str) return str[this.locale];
    return '';
  }

  getStreamid(stream: any): number |null{
    let str = this.streams.find((elem) => elem.key == stream);
    if(str) return str['id'];
    return null;
  }
}
