import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    component: MainLayoutComponent
  },
  {
    path: 'grade',
    loadChildren: () => import('./grade/grade.module').then(m => m.GradeModule),
    component: MainLayoutComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    component: MainLayoutComponent
  },
  {
    path: 'register',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
    component: MainLayoutComponent
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
    component: MainLayoutComponent
  },
  {
    path: 'gallery',
    loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule),
    component: MainLayoutComponent
  },
  {
    path: 'resources',
    loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule),
    component: MainLayoutComponent
  },
  {
    path: 'our-team',
    loadChildren: () => import('./our-team/our-team.module').then(m => m.OurTeamModule),
    component: MainLayoutComponent
  },
  {
    path: 'content-specialists',
    loadChildren: () => import('./content-specialists/content-specialists.module').then(m => m.ContentSpecialistsModule),
    component: MainLayoutComponent
  },
  {
    path: 'content-specialists',
    loadChildren: () => import('./content-specialists/content-specialists.module').then(m => m.ContentSpecialistsModule),
    component: MainLayoutComponent
  },{
    path: 'board-of-directors',
    loadChildren: () => import('./board-of-trustees/board-of-trustees.module').then(m => m.BoardOfTrusteesModule),
    component: MainLayoutComponent
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
    component: MainLayoutComponent
  },
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.module').then(m => m.CareersModule),
    component: MainLayoutComponent
  },
  {
    path: 'what-we-offer',
    loadChildren: () => import('./what-we-offer/what-we-offer.module').then(m => m.WhatWeOfferModule),
    component: MainLayoutComponent
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
    component: MainLayoutComponent
  },
  {
    path: 'leadership',
    loadChildren: () => import('./leadership/leadership.module').then(m => m.LeadershipModule),
    component: MainLayoutComponent
  },
  {
    path: 'help-center',
    loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule),
    component: MainLayoutComponent
  },
  {
    path: 'our-partners',
    loadChildren: () => import('./our-partners/our-partners.module').then(m => m.OurPartnersModule),
    component: MainLayoutComponent
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    component: MainLayoutComponent
  },
  {
    path: 'album/:id',
    loadChildren: () => import('./album/album.module').then(m => m.AlbumModule),
    component: MainLayoutComponent
  },
  {
    path: 'impact',
    loadChildren: () => import('./impact/impact.module').then(m => m.ImpactModule),
    component: MainLayoutComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
