import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SecondaryNavComponent } from './components/secondary-nav/secondary-nav.component';
import { MagicBitSectionComponent } from './components/magic-bit-section/magic-bit-section.component';
import { AlGreetModalContentComponent } from './components/al-greet-modal-content/al-greet-modal-content.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    BreadcrumbComponent,
    SecondaryNavComponent,
    MagicBitSectionComponent,
    AlGreetModalContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    MainLayoutComponent,
    BreadcrumbComponent,
    SecondaryNavComponent,
    MagicBitSectionComponent
  ]
})
export class CoreModule { }
